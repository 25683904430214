import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Orders from "../components/orders";

const IndexPage: FC = () => (
  <Layout header menuState="profile" requireAuth>
    <SEO title="Commandes" />
    <Orders />
  </Layout>
);

export default IndexPage;
