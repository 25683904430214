import styled from "styled-components";
import OButton from "../../../ui/button";

export const Container = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.white};
  margin-bottom: 6px;
  padding: 12px 17px;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const DetailContainer = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const StatusContainer = styled.div`
  width: 35px;
  height: 35px;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const ContentContainer = styled.div`
  flex-direction: row;
  align-items: center;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  width: 100%;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  width: 100%;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProductsContainer = styled.div`
  margin-bottom: 9px;
  width: 100%;
`;

export const ProductContainer = styled.div`
  flex-direction: row;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 0 10px;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    flex-direction: row;
  }
`;

export const Button = styled.button`
  background-color: ${({ theme: { colors } }): string => colors.white};
  color: ${({ theme: { colors } }): string => colors.secondary};
  font-size: 14px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  border: none;
  cursor: pointer;
`;

export const BlueButton = styled(OButton)`
  margin-top: 10px;
  padding: 0 10px;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    margin-left: 10px;
  }
`;

const Text = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
`;

export const DetailPrice = styled(Text)`
  font-size: 12px;
  color: ${({ theme: { colors } }): string => colors.lightGrey};
`;

export const Status = styled(Text)`
  margin-left: 6px;
  font-style: italic;
  font-size: 12px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

export const NormalText = styled(Text)`
  margin-left: 6px;
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

export const BoldText = styled(Text)`
  margin-left: 8px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

export const Quantity = styled(Text)`
  margin-left: 6px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme: { colors } }): string => colors.secondary};
`;
