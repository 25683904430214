import React, { FC, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import {
  Wrapper,
  Container,
  TitleContainer,
  Title,
  SpinnerContainer,
  TitleNoOrderContainer,
} from "./style/orders";
import { AppState } from "../../store";
import OrderDetail from "./order-detail";
import { getOrders } from "../../store/orders/actions";
import { useToast, TOAST_TYPE } from "../../ui/toast";
import Spinner from "../../images/spinner.svg";
import { Order } from "../../types/order";
import { perPage } from "../../utils/config";
import { BackBtnIcon } from "../profile-back-btn-icon";
const INITIAL_PAGE = 1;

const Header: FC = () => {
  const orders = useSelector<AppState, Order[] | undefined>(
    state => state.orders.orders
  );
  const dispatch = useThunkDispatch();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(INITIAL_PAGE);
  const { showToast } = useToast();

  if (
    undefined !== orders &&
    orders.length < page * perPage &&
    !loading &&
    hasMore
  ) {
    setHasMore(false);
  }

  const loadMore = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const nextPage = page + 1;
    setPage(nextPage);
    const response = await dispatch(getOrders(nextPage));
    if (!response.success) {
      showToast(response.error ? response.error : "Error", {
        type: TOAST_TYPE.ERROR,
      });
    }
    setLoading(false);
  }, [
    dispatch,
    showToast,
    page,
    setPage,
    loading,
    orders,
    setLoading,
    loading,
  ]);

  return (
    <Wrapper>
      <TitleContainer>
        <BackBtnIcon />
        <Title>
          <FormattedMessage id="profile.orders.title" />
        </Title>
      </TitleContainer>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={
          <SpinnerContainer key={`spinner${page}`}>
            <Spinner />
          </SpinnerContainer>
        }
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "90%",
        }}
      >
        {orders && orders.length > 0 ? (
          <Container key={page}>
            {orders.map(order => (
              <OrderDetail key={order.id} order={order} />
            ))}
          </Container>
        ) : (
          <TitleNoOrderContainer key={page}>
            {!loading && (
              <Title>
                <FormattedMessage id="profile.orders.noOrder" />
              </Title>
            )}
          </TitleNoOrderContainer>
        )}
      </InfiniteScroll>
    </Wrapper>
  );
};

export default Header;
