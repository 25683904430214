import React, { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { navigate } from "gatsby";

import { useToast, TOAST_TYPE } from '../../ui/toast';
import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import {
  BlueButton,
  ButtonContainer,
  DetailContainer,
  DetailPrice,
  InformationContainer,
  NormalText,
  BoldText,
  PriceContainer,
  ProductContainer,
  ProductsContainer,
  Quantity,
  Button,
} from "./style/order-detail";
import twoDecimals from "../../utils/twoDecimals";
import { Order } from "../../types/order";
import { cancelOrder } from "../../store/orders/actions";

interface Props {
  order: Order;
}

const PastOrderDetail: FC<Props> = ({ order }) => {
  const {
    id,
    dropoffAddress,
    total,
    serviceClass,
    deliveryFee,
    feesTotal,
    discountsTotal,
    items,
    status
  } = order;
  const dispatch = useThunkDispatch();
  const { showToast } = useToast();
  const orderCancel = useCallback(async () => {
    const response = await dispatch(cancelOrder(order));
    if (!response.success)
      showToast(response.error ? response.error : 'Error', { type: TOAST_TYPE.ERROR });
  }, [order, showToast, dispatch]);

  const isNew = useMemo<boolean>(() => {
    return 'NEW' === status;
  }, [status]);

  const isPickupScheduled = useMemo<boolean>(() => {
    return 'PICKUP_SCHEDULED' === status;
  }, [status]);

  const isAwaitingPickup = useMemo<boolean>(() => {
    return 'AWAITING_PICKUP' === status;
  }, [status]);

  const isCanceled = useMemo<boolean>(() => {
    return 'CANCELED' === status;
  }, [status]);

  const isCompleted = useMemo<boolean>(() => {
    return 'COMPLETED' === status;
  }, [status]);

  const isPickupNoshow = useMemo<boolean>(() => {
    return 'NOSHOW_PICKUP' === status;
  }, [status]);

  const isPickupFailed = useMemo<boolean>(() => {
    return 'FAILED_PICKUP' === status;
  }, [status]);

  return (
    <DetailContainer>
      <InformationContainer>
        <NormalText>
          <FormattedMessage id="orderNumber" />
        </NormalText>
        <BoldText>{id}</BoldText>
      </InformationContainer>
      <InformationContainer>
        <NormalText>
          <FormattedMessage id="deliveryAddress" />
        </NormalText>
        <BoldText>{`${dropoffAddress.number} ${dropoffAddress.street}, ${dropoffAddress.postalCode} ${dropoffAddress.city}`}</BoldText>
      </InformationContainer>
      <ProductsContainer>
        {items.map(product => (
          <PriceContainer key={product.name}>
            <ProductContainer>
              <Quantity>{`x${product.quantity}`}</Quantity>
              <NormalText>{product.name}</NormalText>
            </ProductContainer>
            <DetailPrice>{`${twoDecimals(product.total / 100)} €`}</DetailPrice>
          </PriceContainer>
        ))}
      </ProductsContainer>
      <PriceContainer>
        <NormalText>{serviceClass}</NormalText>
        <NormalText>
          {deliveryFee > 0 ? (
            `${twoDecimals(deliveryFee/100)} €`
          ) : (
            <FormattedMessage id="free" />
          )}
        </NormalText>
      </PriceContainer>
      {feesTotal > 0 && (
        <PriceContainer>
          <NormalText>
            <FormattedMessage id="fees" />
          </NormalText>
          <NormalText>{`${twoDecimals(feesTotal/100)} €`}</NormalText>
        </PriceContainer>
      )}
      {discountsTotal > 0 && (
        <PriceContainer>
          <NormalText>
            <FormattedMessage id="discount" />
          </NormalText>
          <NormalText>- {`${twoDecimals(discountsTotal)} €`}</NormalText>
        </PriceContainer>
      )}
      <PriceContainer>
        <NormalText>
          <FormattedMessage id="total" />
        </NormalText>
        <BoldText>{`${twoDecimals(total/100)} €`}</BoldText>
      </PriceContainer>
      <ButtonContainer>
        {/* <Button onClick={orderAgain}>
          <FormattedMessage id="orderAgain" />
        </Button> */}
        {
          (isNew || isAwaitingPickup || isPickupScheduled || isPickupNoshow || isPickupFailed) && (
            <Button onClick={orderCancel}>
              <FormattedMessage id="orderCancel" />
            </Button>
          )
        }
        {
          (!isCanceled && !isCompleted) && (
            <Button onClick={(): Promise<void> =>
              navigate(`/order-reschedule?order=${id}`)
            }>
              <FormattedMessage id="orderReschedule" />
            </Button>
          )
        }
        <BlueButton
          onClick={(): Promise<void> =>
            navigate(`/order-confirmation?order=${id}`)
          }
        >
          <FormattedMessage id="order.seeMoreDetails" />
        </BlueButton>
      </ButtonContainer>
    </DetailContainer>
  );
};

export default PastOrderDetail;
