import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    padding: 0 25%;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 50px 0;
  flex-wrap: wrap;
`;

export const TitleContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const TitleNoOrderContainer = styled(TitleContainer)`
  margin-bottom: 50px;
`;

export const SpinnerContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
`;

export const Text = styled.div`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 15px;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  text-decoration: none;
`;

export const Title = styled(Text)`
  font-size: 18px;
  font-weight: bold;
`;
