import React, { FC, useMemo, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  ContentContainer,
  HeaderContainer,
  NormalText,
  BoldText,
  StatusContainer,
  LeftContainer,
  Status,
  HeaderDescriptionContainer,
} from "./style/order-detail";
import CanceledIcon from "../../images/canceled.svg";
import TimeIcon from "../../images/time-blue.svg";
import CheckIcon from "../../images/check-success.svg";
import Detail from "./detail";
import twoDecimals from "../../utils/twoDecimals";
import { Order } from "../../types/order";
import dayjs from "dayjs";
import userLanguage from "../hooks/useLanguage";

interface Props {
  order: Order;
}

const OrderDetail: FC<Props> = ({ order }) => {
  const { total, status, dropoffTime, pickupTime, facility } = order;
  const intl = useIntl();
  const isCanceled = useMemo<boolean>(() => {
    return "CANCELED" === status;
  }, [status]);

  const isDelivered = useMemo<boolean>(() => {
    return "COMPLETED" === status;
  }, [status]);

  const isAwaitingPickup = useMemo<boolean>(() => {
    return "AWAITING_PICKUP" === status;
  }, [status]);

  const isPickupScheduled = useMemo<boolean>(() => {
    return "PICKUP_SCHEDULED" === status;
  }, [status]);

  const isPickupNoshow = useMemo<boolean>(() => {
    return "NOSHOW_PICKUP" === status;
  }, [status]);

  const isPickupFailed = useMemo<boolean>(() => {
    return "FAILED_PICKUP" === status;
  }, [status]);

  const isAwaitingDeposit = useMemo<boolean>(() => {
    return "AWAITING_DEPOSIT" === status;
  }, [status]);

  const isReachWorkshop = useMemo<boolean>(() => {
    return "REACH_WORKSHOP" === status;
  }, [status]);

  const isItemization = useMemo<boolean>(() => {
    return "ITEMIZATION" === status;
  }, [status]);

  const isCleaning = useMemo<boolean>(() => {
    return "CLEANING" === status;
  }, [status]);

  const isRecleaning = useMemo<boolean>(() => {
    return "RECLEANING" === status;
  }, [status]);

  const isReady = useMemo<boolean>(() => {
    return "READY" === status;
  }, [status]);

  const isAwaitingDropoff = useMemo<boolean>(() => {
    return "AWAITING_DROPOFF" === status;
  }, [status]);

  const isDropoffNoshow = useMemo<boolean>(() => {
    return "NOSHOW_DROPOFF" === status;
  }, [status]);

  const isDropoffFailed = useMemo<boolean>(() => {
    return "FAILED_DROPOFF" === status;
  }, [status]);

  const dateFormat = { day: "numeric", month: "long", year: "numeric" };
  const [displayDetail, setDisplayDetail] = useState<boolean>(false);
  const swicthDisplayDetail = useCallback(
    () => setDisplayDetail(!displayDetail),
    [displayDetail]
  );

  const formatTotal = useMemo<string>(() => {
    if (isCanceled) return "";
    if (total === 0) return intl.formatMessage({ id: "minimumOrderPrice" });
    return `${twoDecimals(total / 100)} €`;
  }, [total]);

  const locale = userLanguage();

  return (
    <Container>
      <HeaderContainer onClick={swicthDisplayDetail}>
        <LeftContainer>
          <StatusContainer>
            {isCanceled && <CanceledIcon />}
            {isDelivered && <CheckIcon />}
            {(isPickupScheduled ||
              isAwaitingPickup ||
              isPickupNoshow ||
              isPickupFailed ||
              isAwaitingDeposit ||
              isReachWorkshop ||
              isItemization ||
              isCleaning ||
              isRecleaning ||
              isReady ||
              isAwaitingDropoff ||
              isDropoffNoshow ||
              isDropoffFailed) && <TimeIcon />}
          </StatusContainer>
          <HeaderDescriptionContainer>
            <ContentContainer>
              <NormalText>
                {/* {facility && facility.name + ", "} */}
                <FormattedMessage id="profile.orders.pickupInfo" />
              </NormalText>
              <BoldText>
                {dayjs(pickupTime.startTime)
                  .locale(locale)
                  .format("dddd DD MMM, HH:mm") +
                  " - " +
                  dayjs(pickupTime.endTime).format("HH:mm")}
              </BoldText>
            </ContentContainer>
            <ContentContainer>
              <NormalText>
                <FormattedMessage id="profile.orders.dropoffInfo" />
              </NormalText>
              <BoldText>
                {dayjs(dropoffTime.startTime)
                  .locale(locale)
                  .format("dddd DD MMM, HH:mm") +
                  " - " +
                  dayjs(dropoffTime.endTime).format("HH:mm")}
              </BoldText>
            </ContentContainer>
            <ContentContainer>
              {isCanceled && (
                <Status>
                  <FormattedMessage id="canceled" />
                </Status>
              )}
              {isDelivered && (
                <Status>{`${intl.formatMessage({ id: "delivered" })} · ${
                  dropoffTime
                    ? new Date(dropoffTime.startTime).toLocaleDateString(
                        "fr-FR",
                        dateFormat
                      )
                    : ""
                }`}</Status>
              )}
              {isPickupScheduled && (
                <Status>
                  <FormattedMessage id="pickupScheduled" />
                </Status>
              )}
              {isAwaitingPickup && (
                <Status>
                  <FormattedMessage id="awaitingPickup" />
                </Status>
              )}
              {isPickupNoshow && (
                <Status>
                  <FormattedMessage id="noshowPickup" />
                </Status>
              )}
              {isPickupFailed && (
                <Status>
                  <FormattedMessage id="failedPickup" />
                </Status>
              )}
              {isAwaitingDeposit && (
                <Status>
                  <FormattedMessage id="awaitingDeposit" />
                </Status>
              )}
              {isReachWorkshop && (
                <Status>
                  <FormattedMessage id="reachWorkshop" />
                </Status>
              )}
              {isItemization && (
                <Status>
                  <FormattedMessage id="itemization" />
                </Status>
              )}
              {isCleaning && (
                <Status>
                  <FormattedMessage id="cleaning" />
                </Status>
              )}
              {isRecleaning && (
                <Status>
                  <FormattedMessage id="recleaning" />
                </Status>
              )}
              {isReady && (
                <Status>
                  <FormattedMessage id="ready" />
                </Status>
              )}
              {isAwaitingDropoff && (
                <Status>
                  <FormattedMessage id="awaitingDropoff" />
                </Status>
              )}
              {isDropoffNoshow && (
                <Status>
                  <FormattedMessage id="noshowDropoff" />
                </Status>
              )}
              {isDropoffFailed && (
                <Status>
                  <FormattedMessage id="failedDropoff" />
                </Status>
              )}
            </ContentContainer>
          </HeaderDescriptionContainer>
        </LeftContainer>
        <ContentContainer>
          <BoldText>{formatTotal}</BoldText>
        </ContentContainer>
      </HeaderContainer>
      {displayDetail && <Detail order={order} />}
    </Container>
  );
};

export default OrderDetail;
